@import url("https://fonts.googleapis.com/css2?family=Philosopher:ital,wght@0,400;0,700;1,400&family=Poppins:ital,wght@0,100;0,400;0,700;1,400&family=Satisfy&display=swap");

:root {
  --card-background: #1f43685e;
  --pale-blue: #87ceeb;
  --paler-blue: #4588b4;
  --medium-blue: #00bfff;
  --off-white: #f5f5f5;
  --dark-blue: #002244;
  --max-width: 1170px;
  --fixed-width: 620px;
  --transition: all 0.3s linear;
}

::selection {
  background: magenta; /* WebKit/Blink Browsers */
}

body::-webkit-scrollbar {
  width: 1vw;
}

body::-webkit-scrollbar-track {
  box-shadow: inset 0 0 16px rgba(0, 0, 0, 0.5);
}

body::-webkit-scrollbar-thumb {
  background: deepskyblue;
}

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  background: var(--dark-blue);
  color: var(--off-white);
  line-height: 1.5;
  font-size: 0.875rem;
}

html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

h1,
h2,
h3,
h4 {
  line-height: 1.25;
  margin-bottom: 0.75rem;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 2rem;
}

h3 {
  font-size: 1.25rem;
}

h4 {
  font-size: 0.875rem;
}

p {
  margin-bottom: 1.25rem;
}

/* GLOBAL CLASSES */

/* SECTION */
.section {
  width: 90vw;
  margin: 0 auto;
  max-width: var(--max-width);
}

@media screen and (min-width: 992px) {
  .section {
    width: 95vw;
  }
}

/* HOME */

.page-links {
  display: hidden;
}
main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  place-items: center;
  justify-content: center;
}
.particles {
  position: absolute;
  height: 100vh;
  width: 100vw;
  z-index: -10;
}
/* SIDEBAR */
.sidebar-toggle {
  position: fixed;
  top: 1.3rem;
  left: 0.9rem;
  font-size: 3.5rem;
  background: transparent;
  border-color: transparent;
  color: white;
  transition: var(--transition);
  cursor: pointer;
  animation: home-icon-scale 4s linear infinite reverse;
  z-index: 99;
}

.sidebar-toggle:hover {
  color: magenta;
}

.sidebar-toggle:active {
  color: var(--pale-blue);
}

.btn {
  text-align: center;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  padding: 0.375rem 0.75rem;
  display: inline-block;
  transition: var(--transition);
  font-size: 0.875rem;
  border: 4px solid var(--paler-blue);
  cursor: pointer;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
  background: var(--off-white);
  color: var(--dark-blue);
  position: relative;
}

.btn:hover {
  transform: translateY(-0.2rem);
  box-shadow: 1rem 1rem 1rem rgba(0, 0, 0, 0.2);
}

.btn:active {
  transform: translateY(0.2rem);
  box-shadow: none;
}

.modal-welcome {
  margin-top: 2vh;
  position: relative;
  bottom: 2vh;
}

.modal-open-text {
  position: relative;
  top: 1vh;
}

.barracks {
  font-size: 2.5rem;
  position: absolute;
  bottom: 10.4vh;
  left: 40%;
}

.fighter-jet {
  font-size: 2rem;
  position: relative;
  top: 1.8vh;
  right: 9vw;
  animation: take-off 2.5s cubic-bezier(1, -0.14, 1, 1) 2s 1;
  animation-timing-function: linear;
  overflow: hidden;
}

.home-header-top,
.home-header-bottom {
  color: transparent;
  font-size: 5rem;
  font-weight: 900;
  -webkit-text-stroke: 3px #fff;
}

.home-header-top {
  position: relative;
  bottom: 1vh;
}

.home-header-bottom {
  position: relative;
  top: 1vh;
}

/* MODAL */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: var(--dark-blue);
  display: grid;
  place-items: center;
  transition: var(--transition);
  visibility: hidden;
  z-index: -1;
  color: black;
}

.sprucey {
  width: 7.5rem;
  position: relative;
  bottom: 15vh;
  border-radius: 50%;
  justify-self: baseline;
  margin: 0 1rem;
  border: 3px var(--dark-blue) solid;
}

.icon-img {
  background: url("./iconImage.png");
}

/* Open/Close Modal */
.show-modal {
  visibility: visible;
  z-index: 10;
}

.modal-container {
  background: url("./flipper.jpg") no-repeat;

  background-size: cover !important;
  /* margin: 1rem auto 0 auto !important;
  width: 88%; */
  border-radius: 0.4rem;
  width: 90vw;
  height: 50vh;
  max-width: var(--max-width);
  text-align: center;
  display: grid;
  place-items: center;
  position: relative;
  border: 3px whitesmoke solid;
  box-shadow: 1rem 1rem 1rem rgba(0, 0, 0, 0.3);
}

.modal-textbox {
  position: absolute;
  width: 90%;
}

.modal-heading {
  position: relative;
  left: 11.5vw;
  top: 4vh;
  color: var(--dark-blue);
}

.modal-bio {
  font-size: 1.25rem;
  position: relative;
  top: 4vh;
  color: var(--dark-blue);
  font-weight: 700;
}

.bubbles img {
  width: 50px;
  animation: bubble 7s linear infinite;
}

.bubbles {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: absolute;
  bottom: -30px;
}

.bubbles img:nth-child(1) {
  animation-delay: 2s;
  width: 25px;
  height: 25px;
}
.bubbles img:nth-child(2) {
  animation-delay: 1s;
  width: 50px;
  height: 50px;
}
.bubbles img:nth-child(3) {
  animation-delay: 3s;
  width: 25px;
  height: 25px;
}
.bubbles img:nth-child(4) {
  animation-delay: 4.5s;
  width: 40px;
  height: 40px;
}
.bubbles img:nth-child(5) {
  animation-delay: 3s;
  width: 20px;
  height: 20px;
}
.bubbles img:nth-child(6) {
  animation-delay: 6s;
  width: 35px;
  height: 35px;
}
.bubbles img:nth-child(7) {
  animation-delay: 7s;
  width: 30px;
  height: 30px;
}

.close-modal-btn {
  position: absolute;
  top: 1rem;
  right: 1rem;
  font-size: 2rem;
  background: transparent;
  border-color: transparent;
  color: crimson;
  cursor: pointer;
  z-index: 10;
  transition: 0.2s all;
}

.close-modal-btn:hover {
  transform: translateY(-0.1rem);
  background: magenta;
}

.close-modal-btn:active {
  transform: translateY(0.1rem);
  background: none;
}

/* SIDEBAR */
.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("./sky.jpg");
  background-position: bottom;
  background-repeat: no-repeat;
  display: grid;
  grid-template-rows: auto 1fr auto;
  row-gap: 1rem;
  transition: var(--transition);
  transform: translate(-100%);
  overflow: hidden;
  z-index: 100;
}

.show-sidebar {
  transform: translate(0);
}

.sidebar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem;
}

.close-btn {
  position: relative;
  left: 33vw;
  font-size: 1.75rem;
  background: transparent;
  border-color: transparent;
  color: crimson;
  transition: var(--transition);
  cursor: pointer;
  margin-top: 0.2rem;
  z-index: 100;
}

.close-btn:hover {
  background: crimson;
  transform: scale(1.1);
}
.close-btn:active {
  background: none;
  transform: scale(1) translateY(0.2rem);
}

.moon {
  width: 300px;
  height: 300px;
  position: fixed;
  top: -20%;
  left: -20%;
  margin: auto;
  overflow: hidden;
  border-radius: 50%;
  box-shadow: 0 0 20px 5px #000 inset, 0 0 200px 2px;
  background: url(./moon.jpg);
  animation: spin 200s linear alternate infinite;
}

.moon::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  border-color: black;
  border-radius: 50%;
  box-shadow: -40px -40px 50px 2px #000 inset;
}

.moon > div {
  width: 200%;
  height: 100%;
}

.cloud-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.cloud {
  position: absolute;
  top: -40vh;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(cloud2.png);
  animation: cloud-animation 1800s linear infinite;
}

.twinkle-box {
  position: absolute;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.night {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotateZ(130deg);
}

.star {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 4px;
  width: 100px;
  background: linear-gradient(-45deg, #5f91ff, rgba(0, 0, 255, 0));
  border-radius: 999px;
  filter: drop-shadow(0 0 6px #699bff);
  animation: star-tail 4s ease-in infinite, star-falling 4s ease-in infinite;
}

.star::before,
.star::after {
  content: "";
  position: absolute;
  top: calc(50% - 2px);
  right: 0;
  height: 4px;
  width: 30px;
  background: linear-gradient(
    -45deg,
    rgba(0, 0, 255, 0),
    #5f91ff,
    rgba(0, 0, 255, 0)
  );
  border-radius: 100%;
  transform: translateX(50%) rotateZ(45deg);
  animation: star-shining 3s ease-in-out infinte;
}

.star::after {
  transform: translateX(50%) rotateZ(-45deg);
}

.star:nth-child(1) {
  top: calc(50% + 260px);
  left: calc(50% - 200px);
  animation-delay: 1s;
}
.star:nth-child(1)::before,
.star:nth-child(1)::after {
  animation-delay: 1s;
}
.star:nth-child(2)::before,
.star:nth-child(2)::after {
  animation-delay: 2s;
}
.star:nth-child(2) {
  top: calc(50% + 110px);
  left: calc(50% - 200px);
  animation-delay: 2s;
}
.star:nth-child(3)::before,
.star:nth-child(3)::after {
  animation-delay: 1.4s;
}
.star:nth-child(3) {
  top: calc(50% + 150px);
  left: calc(50% - 150px);
  animation-delay: 1.4s;
}
.star:nth-child(4)::before,
.star:nth-child(4)::after {
  animation-delay: 3s;
}
.star:nth-child(4) {
  top: calc(50% + 210px);
  left: calc(50% - 100px);
  animation-delay: 3s;
}
.star:nth-child(5)::before,
.star:nth-child(5)::after {
  animation-delay: 1.2s;
}
.star:nth-child(5) {
  top: calc(50% + 90px);
  left: calc(50% - 250px);
  animation-delay: 1.2;
}
.star:nth-child(6)::before,
.star:nth-child(6)::after {
  animation-delay: 5s;
}
.star:nth-child(6) {
  top: calc(50% + 50px);
  left: calc(50% - 250px);
  animation-delay: 5s;
}

.links a {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5rem;
  font-family: "Philosopher", sans-serif;
  text-transform: capitalize;
  padding: 1rem 1.5rem;
  color: var(--off-white);
  transition: var(--transition);
  letter-spacing: 1px;
  position: relative;
  top: 4rem;
}

.links a:hover {
  background-color: rgba(112, 143, 189, 0.829);
  color: var(--dark-blue);
  transform: translateY(-0.2rem);
}

.links a:active {
  transform: translateY(0.2rem);
}

.links a svg {
  font-size: 3.5rem;
  transition: var(--transition);
  color: #002244;
  position: relative;
  right: 2rem;
}

.phone-icon {
  right: 2.8rem !important;
}

.boat {
  position: absolute;
  font-size: 5rem;
  bottom: 20%;
  left: -30%;
  animation: sail 18s linear infinite forwards;
  animation-direction: normal;
  overflow: hidden;
}

.waves {
  position: absolute;
  bottom: -17%;
  z-index: 100;
}

@keyframes home-icon-scale {
  0% {
    transform: rotate(0);
    transform-origin: 50% 40%;
  }

  100% {
    transform: rotate(359deg);
    transform-origin: 50% 45%;
  }
}

@keyframes cloud-animation {
  0% {
    background-position: 0px;
  }
  100% {
    background-position: 5440px;
  }
}

@keyframes star-tail {
  0% {
    width: 100px;
  }
  30% {
    width: 200px;
  }
  100% {
    width: 0;
  }
}

@keyframes star-falling {
  0% {
    transform: translateX(-50rem);
  }
  100% {
    transform: translateX(500rem);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes sail {
  0% {
    bottom: 8%;
    left: -30%;
    transform: translateY(1.2rem) rotate(15deg);
  }

  10% {
    transform: translateY(1.5rem) rotate(10deg);
    bottom: 11%;
  }
  20% {
    transform: translateY(1.7rem) rotate(7deg);
    bottom: 11%;
  }

  35% {
    transform: translateY(1.2rem) rotate(-10deg);
    bottom: 10%;
  }

  50% {
    transform: translateY(0.5rem) rotate(-10deg);
    bottom: 10%;
  }

  65% {
    transform: translateY(-0.35rem) rotate(10deg);
    bottom: 8%;
  }
  85% {
    transform: translateY(0.4rem) rotate(0deg);
    bottom: 8%;
  }

  100% {
    transform: translateY(-1rem) rotate(-10deg);
    bottom: 6%;
    left: 99vw;
  }
}

@keyframes bubble {
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  70% {
    opacity: 1;
  }
  100% {
    transform: translateY(-80vh);
    opacity: 0;
  }
}

@keyframes take-off {
  0% {
    transform: translate(0, 0) rotate(0);
  }
  5% {
    transform: translate(0, 0) rotate(0);
  }
  10% {
    transform: translate(10%, 0) rotate(0);
  }
  20% {
    transform: translate(20%, 0) rotate(0);
  }
  30% {
    transform: translate(30%, -5%) rotate(-2deg);
  }
  40% {
    transform: translate(40%, -10%) rotate(-4deg);
  }
  50% {
    transform: translate(50%, -20%) rotate(-8deg);
  }
  60% {
    transform: translate(60%, -35%) rotate(-12deg) scale(0.9);
  }
  70% {
    transform: translate(70%, -50%) rotate(-15deg) scale(0.8);
  }
  80% {
    transform: translate(80%, -70%) rotate(-20deg) scale(0.7);
  }
  90% {
    transform: translate(90%, -90%) rotate(-25deg) scale(0.6);
  }
  100% {
    transform: translate(110%, -110%) rotate(-25deg) scale(0.5);
  }
}

.social-icons {
  justify-self: center;
  display: flex;
  padding-bottom: 0rem;
  position: relative;
  top: -2.5rem;
  grid-gap: 2.5rem;
  z-index: 101;
}

.social-icons a {
  font-size: 2.5rem;
  margin: 0 0.5rem;
  color: var(--dark-blue);
  transition: var(--transition);
  position: relative;
  top: 1rem;
}

.social-icons a:hover {
  color: var(--off-white);
}

svg {
  transition: 0.2s all;
}

svg:active {
  transform: translateY(0.2rem);
}

/* CARDS CONTAINER */

.card-title {
  color: var(--off-white);
}
.card-element {
  border: 3px rgb(18, 178, 231) solid;
}
.card-element:not(:last-child) {
  margin-bottom: 6vh;
}

.card-element-background {
  background-color: var(--card-background) !important;
}

.card-picture-1 {
  background-image: url("./favicon.png") !important;
  background: var(--off-white);
  height: 40vh !important;
  content: "";
  display: block;
  margin: 2vh auto 0 auto;
  width: 85%;
  padding-top: 2vh;
  border: 3px solid var(--dark-blue);
}

.card-picture-2 {
  background-image: url("./bonnie.png") !important;
  height: 40vh !important;
  background-size: fill !important;

  margin: 1rem auto 0 auto !important;
  width: 88%;

  border: 3px solid var(--dark-blue);
}

.card-picture-3 {
  background-image: url("./iconImage.png") !important;
  height: 40vh !important;
  background-size: cover !important;
  margin: 1rem auto 0 auto !important;

  background-color: var(--off-white);
  border: 3px solid var(--pale-blue);
}

.card-header-text {
  color: var(--dark-blue);
  text-align: center;
}

.card-body-text {
  color: var(--dark-blue) !important;
  text-align: center;
}

.card-footer {
  background-color: var(--dark-blue);
}

.card-footer-icon {
  color: var(--off-white) !important;
  position: relative;
  left: 3vw;
}
.card-footer-icon1 {
  color: var(--off-white) !important;
  font-size: 1.3rem !important;
}
/********************************************/

/*TECH STACK EXPERIENCE CONTAINER*/

.techExperience {
  margin-top: 5vh;
  min-height: 92vh;
  position: relative;
}

.tech-section-header {
  position: relative;
  color: var(--off-white);
  top: 0.3vh;
  margin-top: 4.6vh;
}

.underline {
  width: 5rem;
  height: 0.25rem;
  background: var(--medium-blue);
  margin: 0 auto 1.25rem auto;
}

.underline-1 {
  position: relative;
  top: 4vh;
}

.title {
  margin-bottom: 4rem;
  text-align: center;
}

.tech-center {
  width: 80vw;
  margin: 0 auto;
  max-width: var(--max-width);
}
.btn-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 3rem;
  flex-wrap: wrap;
  position: relative;
  bottom: 3vh;
}

.tech-btn {
  background: transparent;
  border-color: transparent;
  text-transform: capitalize;
  font-size: 1.25rem;
  letter-spacing: 1px;
  margin: 0.5rem 0.5rem;
  transition: var(--transition);
  cursor: pointer;
  padding: 0.25rem 0;
  line-height: 1;
  outline-color: var(--medium-blue);
  color: var(--paler-blue);
}
.tech-btn:hover {
  color: var(--medium-blue);
  box-shadow: 0 2px var(--pale-blue);
}
.active-btn {
  color: var(--off-white) !important;
  filter: brightness(100%);
  box-shadow: 0 2px var(--medium-blue);
}

.tech-info {
  position: relative;
  bottom: 4vh;
}

.tech-info h3 {
  font-weight: 400;
  font-size: 1.5rem;
}

.tech-info h4 {
  text-transform: uppercase;
  color: var(--dark-blue);
  background: var(--off-white);
  display: inline-block;
  padding: 0.375rem 0.75rem;
  border-radius: 1rem;
  font-size: 1rem;
}

.tech-date {
  letter-spacing: var(--spacing);
  font-size: 1rem;
}

.tech-desc {
  display: grid;
  grid-template-columns: auto 1fr;
  column-gap: 2rem;
  align-items: center;
  margin-top: 5vh;
  margin-bottom: 1.25rem;
  font-size: 1rem;
}

.tech-desc p {
  margin-bottom: 0;
  color: var(--off-white);
  filter: brightness(95%);
  font-family: poppins;
}
.tech-icon {
  color: var(--medium-blue);
}

.sextant {
  font-size: 3.5rem;
  position: relative;
  left: 9vw;
  transform: rotate(-25deg);
  margin-bottom: -2vh;
}

/* .btnTech {
  text-transform: uppercase;
  background: red;
  color: rebeccapurple;
  padding: 0.375rem 0.75rem;
  letter-spacing: 1px;
  font-weight: 700;
  -webkit-transition: var(--transition);
  transition: var(--transition);
  font-size: 0.875rem;
  border: 2px solid transparent;
  cursor: pointer;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
  border-radius: 1rem;
  display: block;
  width: 12rem;
  text-align: center;
  margin: 0 auto;
  margin-top: 3rem;
}
.btnTech:hover {
  color: pink;
  background: pink;
} */

.treasure-chest {
  position: absolute;
  right: 0;
  font-size: 7rem;
}

/********************************************/
/*EXTRA WORKS CONTAINER*/
.works-header {
  margin-top: 5vh;
}

.works-underline-1 {
  position: relative;
  top: 6.2vh;
}

.container {
  width: 80vw;
  max-width: var(--fixed-width);
}

.works {
  background: var(--paler-blue);
  padding: 2.5rem 2rem;
  border-radius: 1rem;
  box-shadow: 0.5rem 0.5rem 0.5rem rgba(0, 0, 0, 0.3);
  transition: var(--transition);
  text-align: center;
  width: 80vw;
  margin-top: 2.5vh;
  margin-bottom: 5vh;
  border: 3px var(--medium-blue) solid;
}

.works:hover {
  box-shadow: 1rem 1rem 1rem rgba(0, 0, 0, 0.3);
}

.fan-icon {
  border-radius: 50%;
  transform: translateY(25%);
  background: var(--off-white);
  color: black;
  font-size: 5rem;

  fill: var(--dark-blue);
}

.fan {
  color: var(--dark-blue);
  position: relative;
  top: 1.6vh;
}

.fan:hover {
  color: magenta;
}

.fan:active {
  color: var(--dark-blue);
}

/* @keyframes fan-spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(359deg);
  }
} */

.img-container::before {
  content: "";
  width: 100%;
  height: 100%;
  background: var(--dark-blue);
  position: absolute;
  top: -0.25rem;
  right: -0.5rem;
  border-radius: 50%;
}

.name {
  margin-bottom: 0.25rem;
}

.project {
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  color: var(--dark-blue);
  font-size: 0.85rem;
  font-family: poppins;
}
.info {
  margin-bottom: 0.75rem;
  color: var(--off-white);
  font-family: poppins;
}

.prev-btn,
.next-btn {
  color: var(--dark-blue);
  font-size: 2rem;
  background: transparent;
  border-color: transparent;
  margin: 0 2rem;
  transition: var(--transition);
  cursor: pointer;
}

.prev-btn:hover,
.next-btn:hover {
  color: var(--medium-blue);
}

.random-btn {
  margin-top: 2vh;
  background: var(--dark-blue);
  color: var(--off-white);
  padding: 0.5rem 0.7rem;
  text-transform: capitalize;
  border-radius: 1rem;
  transition: var(--transition);
  border: 3px rgb(2, 216, 253) solid;

  cursor: pointer;
}
.random-btn:hover {
  background: var(--medium-blue);
  color: var(--dark-blue);
  border: 3px var(--dark-blue) solid;
}

.icon {
  font-size: 4rem;
  color: var(--dark-blue) !important;
}

/********************************************/
/*FOOTER*/
footer {
  min-height: 25vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.7rem;
  font-family: "Satisfy", cursive;
  color: #fff;
}

.footer-button {
  transition: var(--transition);
}

.footer-button:hover {
  transform: translateY(-0.3rem);
}

.footer-button:active {
  transform: translateY(0rem);
}

.neonP {
  /* animation: p 0.11s ease-in-out 10s infinite alternate; */
  border: 0.2rem solid #fff;
  padding: 1rem;
  border-radius: 2rem;
  box-shadow: 0 0 0.2rem #fff, 0 0 0.2rem #fff, 0 0 2rem #3340b1,
    0 0 0.8rem #2729a8, 0 0 2.8rem #431cc2, inset 0 0 1.3rem #6765db;
}

@keyframes p {
  100% {
    text-shadow: 0 0 4px #fff, 0 0 11px #fff, 0 0 19px #fff, 0 0 42px #0829be,
      0 0 82px #0d2bb1, 0 0 92px #0c28a8, 0 0 102px #092086, 0 0 151px #050d33;
  }
}

.neontext {
  color: #fff;
  text-shadow: 0 0 7px #fff, 0 0 10px #fff, 0 0 21px #fff, 0 0 42px #0829be,
    0 0 82px #0d2bb1, 0 0 92px #0c28a8, 0 0 102px #092086, 0 0 151px #050d33;
}

/********************************************/
/* ABOUT PAGE */
.glitch {
  font-size: 6rem;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  text-shadow: 0.1rem 0 0 rgba(182, 32, 149, 0.75),
    0.15rem 0.1rem 0 rgba(0, 225, 255, 0.75), -0.2rem -0.1rem 0 var(--dark-blue);
  animation: glitch 0.2s infinite;
  position: relative;
  color: yellow;
}

.glitch span {
  position: absolute;
  top: 0;
  left: 0;
}

.glitch span:first-child {
  animation: glitch 0.3s infinite;
  color: yellow;
  z-index: 3;
  clip-path: polygon(0 90%, 0% 90%, 100% 60%, 0 35%);
  transform: translate(-0.2rem, -0.2rem);
}

.glitch span:last-child {
  animation: glitch 0.4s infinite;
  clip-path: polygon(10% 35%, 70% 10%, 50% 40%, 0 30%);
  transform: translate(0.2rem, 0.2rem);
  color: yellow;
  z-index: 2;
}

@keyframes glitch {
  0% {
    text-shadow: 0.15rem 0 0 rgba(182, 32, 149, 0.75),
      -0.15rem -0.25rem 0 rgba(0, 225, 255, 0.75),
      -0.13rem -0.15rem 0 var(--dark-blue);
  }
  14% {
    text-shadow: 0.15rem 0 0 rgba(182, 32, 149, 0.75),
      -0.15rem -0.25rem 0 rgba(0, 225, 255, 0.75),
      -0.25rem 0.15rem 0 var(--dark-blue);
  }
  15% {
    text-shadow: -0.15rem -0.25rem 0 rgba(182, 32, 149, 0.75),
      0.25rem 0.25rem 0 rgba(0, 225, 255, 0.75),
      -0.15rem -0.15rem 0 var(--dark-blue);
  }
  49% {
    text-shadow: -0.15rem -0.25rem 0 rgba(182, 32, 149, 0.75),
      0.25rem 0.25rem 0 rgba(0, 225, 255, 0.75),
      -0.15rem -0.15rem 0 var(--dark-blue);
  }
  50% {
    text-shadow: 0.25rem 0.15rem 0 rgba(182, 32, 149, 0.75),
      0.15rem 0rem 0 rgba(0, 225, 255, 0.75), 0rem -0.2rem 0 var(--dark-blue);
  }
  99% {
    text-shadow: 0.25rem 0.15rem 0 rgba(182, 32, 149, 0.75),
      0.15rem 0rem 0 rgba(0, 225, 255, 0.75), -0.1rem -0.2rem 0 var(--dark-blue);
  }
  100% {
    text-shadow: -0.1rem 0 0 rgba(182, 32, 149, 0.75),
      -0.025rem -0.1rem 0 rgba(0, 225, 255, 0.75),
      -0.03rem -0.1rem 0 var(--dark-blue);
  }
}

.about-arrow {
  font-size: 5rem;
  position: relative;
  top: 7vh;
  animation: pointer 4s linear infinite;
}

.about-me {
  width: 93vw;
  color: var(--off-white);
  border: 3px #fff solid;
  padding: 0.5rem 0.5rem;
  margin-top: 10vh;
}

.about-me-header {
  text-align: center;
  margin-top: 1vh;
}

.about-p {
  font-size: 1.1rem;
  text-align: left;
  font-family: poppins;
}

.about-img {
  margin-top: 10vh;
  border-radius: 50%;
  border: 3px white solid;
  width: 70vw;
}

.img-icons-container {
  text-align: center;
  font-size: 4rem;
  position: relative;
  top: 4vh;
  right: 11vw;
  margin-bottom: 2.5vh;
}

.about-icons {
  margin-left: 20vw;
  background-color: transparent;
  color: var(--off-white);
  transition: var(--transition);
}

.about-icons:hover {
  background-color: none;
  color: var(--medium-blue);
  transform: scale(1.1);
}

.about-icons:active {
  background-color: none;
  transform: scale(1);
}

@keyframes pointer {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10vh);
  }
  100% {
    transform: translateY(0);
  }
}
/* CONTACT */

.contact-header {
  font-family: poppins;
  text-align: center;
  margin-top: 7vh;
}

.contact-form-header {
  text-align: center;
  font-family: poppins;
  padding: 1rem;
}

.contact-form {
  padding: 0 1rem 0 1rem;
  text-transform: capitalize;
}

.contact-form-label-top {
  padding-right: 0.3rem;
  color: var(--pale-blue);
  font-size: 1rem;
  font-family: poppins;
}

.contact-form-input-top {
  padding: 0.6rem;
  width: 100%;
  margin-bottom: 0.6rem;
  border: 3px var(--pale-blue) solid;

  font-size: 1.2rem;
}

.contact-inputbox-label {
  color: var(--pale-blue);
  font-size: 1rem;
  font-family: poppins;
}

.contact-container {
  background-color: #23455b;
  max-width: 85%;
  border-radius: 5px;
  box-shadow: 1rem 0.7rem 1rem rgba(0, 0, 0, 0.2);
  border: 3px solid var(--pale-blue);
}

.contact-input {
  background-color: var(--paler-blue);
  color: var(--off-white);
  width: 100%;
  display: flex;
  text-indent: 5px;
  font-size: 1.2rem;
  border-radius: 0.6rem;
  margin-bottom: 1rem;
  border: 3px var(--pale-blue) solid;
}

.form-control {
  display: flex;
  justify-content: space-between;
  margin: 0.5rem 0;
  color: #142834;
}

.contact-button {
  padding: 0.4rem 0.6rem;
  text-align: center;
  margin: 1rem 0 1.5rem 65%;
  width: 30%;
  letter-spacing: 2px;
  color: var(--off-white);
  font-weight: bold;
  font-family: poppins;
  border: 3px var(--pale-blue) solid;
  cursor: pointer;
  transition: var(--transition);
}

.contact-button:hover {
  transform: translateY(-0.3vh);
  box-shadow: 0rem 0.2rem 0.5rem rgba(0, 0, 0.3);
}

.contact-button:active {
  transform: translateY(0vh);
  box-shadow: none;
}

.contact-page {
  margin-top: -5vh;
}

input {
  background-color: var(--paler-blue);
  outline: none;
  border: none;
  border-radius: 5px;
}

label {
  color: var(--paler-blue);
}

/* ERROR PAGE */
.error-svg {
  width: 80%;
  position: absolute;
  left: 10%;
  top: -20vh;
}

.error-heading {
  text-align: center;
}

.error-btn {
  position: relative;
  top: 15vh;
  left: 36vw;
  border: 5px var(--medium-blue) solid;
}
/********************************************/
@media screen and (max-width: 675px) {
  .wave {
    position: relative;
    top: 0.5vh !important;
    z-index: -10;
  }

  .card-picture-3 {
    width: 88%;
  }
}

@media screen and (max-width: 400px) {
  .wave {
    position: relative;
    top: -6vh !important;
    z-index: -10;
  }
}

@media screen and (max-width: 375px) {
  .contact-header {
    font-size: 1.3rem;
    padding: 0 2rem;
  }

  .card-picture-2 {
    width: 84%;
    padding: 1rem;
  }

  .card-picture-3 {
    width: 88%;
  }

  .contact-form-header {
    font-size: 1.3rem;
  }

  .contact-form-label-top {
    font-size: 1rem;
  }

  .contact-inputbox-label {
    font-size: 1rem;
  }

  .contact-button {
    margin: 0 0 1rem 65%;
  }

  .card-element {
    width: 90%;
    margin: 0 auto;
  }

  .waves {
    position: absolute;
    bottom: -17%;
    z-index: 100;
  }

  .wave {
    position: relative;
    top: -10vh !important;
    z-index: -10;
  }

  .set-wave {
    position: relative;
    top: -11.5vh;
    z-index: -10;
  }

  .links a {
    font-size: 2rem;
  }

  .social-icons {
    top: -10vh;
  }

  .modal-container {
    height: 65vh;
  }

  .sprucey {
    top: -19vh;
    right: 3vw;
  }

  .modal-heading {
    font-size: 1.8rem;
    top: 5vh;
  }

  .fighter-jet {
    right: 11vw;
    top: 2vh;
  }

  .barracks {
    bottom: 13vh;
  }
}

@media screen and (max-width: 400px) {
  .contact-header {
    font-size: 1.3rem;
    padding: 0 2rem;
  }

  .contact-form-header {
    font-size: 1.3rem;
  }

  .barracks {
    bottom: 12vh;
  }

  .fighter-jet {
    right: 10vw;
    top: 2vh;
  }

  .modal-container {
    height: 65vh;
  }

  .set-wave {
    position: relative;
    top: -6vh;
    z-index: -10;
  }

  .links a {
    font-size: 2rem;
  }

  .social-icons {
    top: -9vh;
  }

  .sprucey {
    top: -21.5vh;
    right: 3vw;
  }

  .modal-heading {
    font-size: 1.8rem;
    top: 4vh;
  }
}

@media screen and (min-width: 676px) {
  .sidebar {
    width: 400px;
  }

  .techs-center {
    width: 90vw;
    display: grid;
    grid-template-columns: 200px 1fr;
    column-gap: 4rem;
  }

  .btn-container {
    flex-direction: column;
    justify-content: flex-start;
  }

  .tech-btn {
    margin-bottom: 1rem;
  }

  .active-btn {
    box-shadow: -2px 0 var(--clr-primary-5);
  }

  .tech-btn:hover {
    box-shadow: -2px 0 var(--clr-primary-5);
  }

  .modal-heading {
    top: 4vh;
  }
}

@media screen and (min-width: 800px) {
  h1 {
    font-size: 4rem;
  }

  h2 {
    font-size: 2.5rem;
  }

  h3 {
    font-size: 1.75rem;
  }

  h4 {
    font-size: 1rem;
  }

  body {
    font-size: 1rem;
  }

  .boat {
    bottom: 20%;
    left: 0%;
    animation: sail 15s linear infinite forwards;
    animation-direction: normal;
  }

  .close-btn {
    left: 8vw;
  }

  .modal-container {
    max-width: 40vw;
  }

  @keyframes sail {
    0% {
      bottom: 0;
      left: -10vw;
      transform: translateY(0rem) rotate(0deg);
    }

    15% {
      transform: translateY(2.8rem) rotate(12deg);
      bottom: 15%;
    }

    35% {
      transform: translateY(1.1rem) rotate(-10deg);
      bottom: 10%;
    }

    65% {
      transform: translateY(-1.6rem) rotate(10deg);
      bottom: 7%;
    }

    100% {
      transform: translateY(0.8rem) rotate(-15deg);
      bottom: 10%;
      left: 100%;
    }
  }

  .cards-container {
    display: grid;
    grid-template-columns: repeat(3, auto);
    grid-gap: 5rem;
  }

  .treasure-chest {
    font-size: 24rem;
  }

  .works {
    width: 40%;
  }

  .fan-icon {
    left: 42.5%;
  }

  .modal-heading {
    left: 0;
    top: 0;
  }

  .card-picture-2 {
    width: 89%;
    position: relative;
    margin: 1rem auto 0 auto;
    border: 3px solid var(--dark-blue);
  }

  .card-picture-3 {
    width: 90%;
    position: relative;
    left: 0;
  }

  .card-element {
    height: fit-content;
  }

  .info {
    padding: 1.5rem 0;
  }

  .about-me {
    width: 80%;
    border: none;
  }

  .about-p {
    padding: 0 1.5rem;
  }

  .about-img {
    width: 22vw;
    margin-top: 0;
    margin-left: 28%;
  }

  .img-icons-container {
    margin-bottom: 5vh;
    margin-left: 2vw;
  }

  .fighter-jet {
    font-size: 2rem;
    right: 3.3vw;
  }

  .error-svg {
    width: 40%;
    left: 30vw;
    padding-bottom: 5vh;
    top: -10vh;
  }

  .error-btn {
    position: relative;
    left: 40%;
  }

  .error-heading {
    position: relative;
    top: 12vh;
  }

  .card-footer-icon {
    color: var(--off-white) !important;
    left: 1vw;
  }
  .card-footer-icon1 {
    color: var(--off-white) !important;
    font-size: 1.3rem !important;
  }
}
@media screen and (min-height: 800px) {
  .wave {
    position: relative;
    top: 2vh !important;
  }
}
